import { debounce } from '../utils/debounce'
import { trackComponent } from './tracking'

function init(carousel) {
  const carouselContainer = carousel.querySelector(
    '[data-platinum-carousel-target="carouselContainer"]',
  )
  const carouselCards = carousel.querySelectorAll('[data-platinum-carousel-target="carouselCard"]')
  const carouselButtons = carousel.querySelectorAll(
    '[data-platinum-carousel-target="carouselButtons"]',
  )
  const leftArrow = carousel.querySelector('[data-action="leftArrowClick"]')
  const rightArrow = carousel.querySelector('[data-action="rightArrowClick"]')
  const leftArrowIcon = carousel.querySelector('[data-action="leftArrowIconClick"]')
  const rightArrowIcon = carousel.querySelector('[data-action="rightArrowIconClick"]')

  let scrollCounter = 0
  const cardLength = carouselCards.length
  const brand = window.__BRAND__

  const trackScroll = (newIndex: number) => {
    const activeCard = carouselCards[newIndex]
    const trackingProps =
      activeCard?.getAttribute('data-scroll-tracking') &&
      JSON.parse(activeCard.getAttribute('data-scroll-tracking')!)
    const componentData = {
      component_category: trackingProps?.component_category,
      component_id: trackingProps?.component_id,
      component_name: trackingProps?.component_name,
      contentful_name: trackingProps?.contentful_name,
      contentful_variant: trackingProps?.contentful_variant,
    }
    trackComponent('Dynamic Component Event', 'component_scrolled', componentData, {
      ...trackingProps?.additionalProps,
      carouselPosition: newIndex + 1,
    })
  }

  const scrollCards = (e, distance) => {
    e.preventDefault()
    const useLeft = leftArrowIcon.querySelector('use')
    const useRight = rightArrowIcon.querySelector('use')

    const cardWidth = carouselCards[0].offsetWidth + 16
    scrollCounter += distance
    carouselContainer.scrollBy({ left: cardWidth * distance })

    useLeft.setAttribute('href', `/fs-core-assets/icons/${brand}/arrow-left.svg?v=5#arrow-left`)
    useRight.setAttribute('href', `/fs-core-assets/icons/${brand}/arrow-right.svg?v=5#arrow-right`)
    if (scrollCounter === 0) {
      useLeft.setAttribute(
        'href',
        `/fs-core-assets/icons/${brand}/ButtonLeftDisabled.svg?v=5#ButtonLeftDisabled`,
      )
    }
    if (scrollCounter === cardLength - 3) {
      useRight.setAttribute(
        'href',
        `/fs-core-assets/icons/${brand}/ButtonRightDisabled.svg?v=5#ButtonRightDisabled`,
      )
    }
  }

  const updateButtons = debounce(() => {
    const newIndex = Array.from(carouselCards)
      .map(
        (ele) =>
          ele.getBoundingClientRect().left > 0 &&
          ele.getBoundingClientRect().left < window.innerWidth / 2,
      )
      .lastIndexOf(true)

    carouselButtons.forEach((button, i) => {
      if (i === newIndex) {
        button.setAttribute('data-vanilla-carousel-nav-button-active', 'true')
        trackScroll(newIndex)
      } else {
        button.setAttribute('data-vanilla-carousel-nav-button-active', 'false')
      }
    })
  }, 50)

  carouselContainer.addEventListener('scroll', updateButtons)

  carouselButtons.forEach((button, index) => {
    button.addEventListener('click', () => {
      const cardWidth = carouselCards[0].offsetWidth
      const activeIndex = Array.from(carouselButtons).findIndex((b) => {
        const dataset = b.dataset
        return dataset.vanillaCarouselNavButtonActive === 'true'
      })
      carouselContainer.scrollBy((index - activeIndex) * cardWidth, 0)
    })
  })

  if (leftArrow) {
    leftArrow.addEventListener('click', () => {
      const cardWidth = carouselCards[0].offsetWidth
      carouselContainer.scrollBy(-1 * (cardWidth / 2), 0)
    })
  }
  if (rightArrow) {
    rightArrow.addEventListener('click', () => {
      const cardWidth = carouselCards[0].offsetWidth
      carouselContainer.scrollBy(cardWidth / 2, 0)
    })
  }

  if (leftArrowIcon) {
    leftArrowIcon.addEventListener('click', (e) => {
      if (scrollCounter !== 0) {
        scrollCards(e, -1)
      }
    })
  }
  if (rightArrowIcon) {
    rightArrowIcon.addEventListener('click', (e) => {
      if (scrollCounter !== cardLength - 3) {
        scrollCards(e, 1)
      }
    })
  }
}

function run() {
  const carousels = document.querySelectorAll('[data-controller="platinum-carousel"]')
  Array.from(carousels).forEach((carousel) => init(carousel))
}

run()
